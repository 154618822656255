import { create } from 'zustand';

type HeaderTransparencyState = {
  transparentHeader: boolean;
  setTransparentHeader: (value: boolean) => void;
  resetTransparentHeader: () => void;
};

const initialState = false as const;

const useHeaderTransparencyStore = create<HeaderTransparencyState>(set => ({
  transparentHeader: initialState,
  setTransparentHeader: (value: boolean) => set(() => ({ transparentHeader: value })),
  resetTransparentHeader: () => set(() => ({ transparentHeader: initialState })),
}));

export { useHeaderTransparencyStore };
