export default {
  'raffle.text': 'Limited to one raffle entry per customer. Misconduct will result in disqualification',
  'raffle.placeholder': 'E-mail',
  'raffle.submit': 'Enter Raffle',
  '404.text': 'Try searching again, double check the spelling & limit the search to one or two words.',
  '404.title': "Sorry, we couldn't find the page you were looking for",
  '404.linkText': 'return to homepage',
  'noSearch.header': 'Your search for ',
  'noSearch.text': 'Try another search term or ',
  'noFilters.message': "Sorry, we couldn't find any results for selected filters",
  'productGrid.noProducts': 'No products!',
  'addtocart.label': 'Add to cart',
  'noSize.label': 'NO SIZE',
  'selectSize.label': 'Select size',
  'addtocart.outofstock': 'OUT OF STOCK',
  'backInStock.success': 'You have successfully subscribed, we will inform you when this product back in stock',
  'checkout.backToShop': 'EDIT BASKET ',
  'checkout.headerLabel': 'Go TO CHECKOUT',
  'cookieBar.title': 'Privacy & Cookies',
  'cookieBar.cookiesDisabled': 'In order for the site to work properly, please enable cookies in your browser.',
  'cookieBar.noConsent': 'We use cookies to improve your shopping experience.',
  'cookieBar.noConsentButton': 'Accept cookies',
  'cookieBar.noConsentLink': ' Read more',
  'email.empty': 'E-mail cannot be empty',
  'input.noConsenst': 'Please accept consent',
  'email.incorrect': 'Please enter a valid e-mail',
  'email.success': 'You joined our mailing list succesfully!',
  'email.fail': 'There was an error. Please try again later',
  'error.loading': 'Loading...',
  'error.sad': 'Error :(',
  'error.somethingWrong': 'Something went wrong.',
  'filters.sortBy': 'Sort By {sortBy}',
  'images.noImages': 'No images yet',
  'info.freeShipping': 'FREE SHIPPING WORLDWIDE',
  'input.name': 'Name',
  'input.phoneNumber': 'Phone number',
  'input.placeholder': 'Your email',
  'input.message': 'Message',
  'input.agree': 'I agree to the legal stuff',
  'input.submit': 'Join',
  'instagram.follow': 'Follow us on instagram',
  'link.description': 'details',
  'link.payment': 'PAYMENT',
  'link.shipping': 'SHIPPING & RETURNS',
  'link.sizeGuide': 'size guide',
  'navigation.more': 'Load more',
  'newsletter.fail': 'We were unable to process your request now, please try again later',
  'newsletter.footertext': 'news, raffles, collaborations & more',
  'newsletter.footertitle': 'Join our mailing list for',
  'newsletter.success': 'You have successfully subscribed!',
  'newsletter.optionsText': 'Avaliable lists',
  'newsletter.male': 'Men',
  'newsletter.female': 'Women',
  'outOfStock.buttonLabel': 'NOTIFY ME',
  'outOfStock.message': 'Out of stock - Notify me when product is back in stock.',
  'outOfStock.option': 'notify me when back in stock',
  'outofstock.label': 'Out of Stock',
  'overlay.close': 'Close',
  'product.soldOut': 'Sold out',
  'search.header': 'Your search for ',
  'selectsize.label': 'Size',
  'selectlength.label': 'Length',
  'selectWaist.label': 'Waist',
  'sizeguide.label': 'Need help?',
  'shopthelook.wornwith': 'Worn with',
  'sort.high': 'Sort by price: high to low',
  'sort.latest': 'Sort by latest',
  'sort.low': 'Sort by price: low to high',
  'sort.popularity': 'Sort by popularity',
  'product.sale': 'Final sale',
  'product.commingSoon': 'Comming soon',
};
