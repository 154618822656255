import { useMemo } from 'react';

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { concatPagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

let apolloClient: ApolloClient<object>;
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const isServer = typeof window === 'undefined';
const uri = isServer ? `http://localhost:${process.env.PORT || 3000}/graphql` : '/graphql';
function createApolloClient(initialState = {}, headers) {
  return new ApolloClient({
    ssrMode: isServer, // set to true for SSR
    link: new HttpLink({
      uri,
      credentials: 'same-origin',
      headers: {
        // ...init.headers,
        Cookie: headers.cookie,
      },
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
      ...initialState,
    }),
  });
}

export function addApolloState(client: ApolloClient<any>, pageProps: any) {
  let nextProps: object;
  if (pageProps?.props) {
    nextProps = {
      ...pageProps,
      props: {
        ...pageProps.props,
        [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
      },
    };
  }

  return { ...nextProps };
}

export function useApollo(pageProps?: any) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}

export function initializeApollo(initialState = {}, headers = {}) {
  const _apolloClient = apolloClient ?? createApolloClient(initialState, headers);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    // @ts-expect-error
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}
