import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { ApolloProvider } from '@apollo/client';
import { useHeaderTransparencyStore } from '@components/contexts/useTransparentHeaderStore';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { messages as messagesDefs } from '../i18n/messages-definitions';
import { useApollo } from '../lib/apolloClient';

import '../assets/styles/global.scss';
import '../assets/styles/tailwind.css';

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { resetTransparentHeader } = useHeaderTransparencyStore();
  const apolloClient = useApollo(pageProps);
  const messages = messagesDefs.en;

  useEffect(() => {
    router.events.on('routeChangeComplete', resetTransparentHeader);

    return () => {
      router.events.off('routeChangeComplete', resetTransparentHeader);
    };
  }, [router.events, resetTransparentHeader]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.remove();
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <IntlProvider locale="en" defaultLocale="en" messages={messages}>
        <Component {...pageProps} />
      </IntlProvider>
    </ApolloProvider>
  );
}

export default App;
